import React from 'react'

const MoneyByAfterpay = () => {
  return (
    <svg
      width="333"
      height="157"
      viewBox="0 0 333 157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.021 68.4899V89.2663C43.021 90.7982 41.8047 92.0352 40.3091 92.0352C38.8183 92.0352 37.5972 90.7935 37.5972 89.2663V73.6329L32.074 80.1174C31.667 80.5932 31.0849 80.8644 30.4649 80.8644C29.8449 80.8644 29.258 80.5885 28.8557 80.1127L23.342 73.5901V89.2663C23.342 90.7982 22.1257 92.0352 20.6301 92.0352C19.1393 92.0352 17.9182 90.7935 17.9182 89.2663V68.4899C17.9182 66.6155 19.409 65.093 21.2454 65.093H21.3826C22.4049 65.093 23.3515 65.5688 23.9857 66.3871L30.4885 74.8032L37.105 66.3681C37.7392 65.5593 38.681 65.093 39.6938 65.093C41.5302 65.0883 43.021 66.6155 43.021 68.4899Z"
        fill="black"
      />
      <path
        d="M63.0834 64.7793C55.35 64.7793 49.292 70.869 49.292 78.6381C49.292 86.4501 55.2175 92.3447 63.0834 92.3447C70.9635 92.3447 76.9126 86.4548 76.9126 78.6381C76.9079 70.8737 70.8357 64.7793 63.0834 64.7793ZM63.0834 87.1922C58.4642 87.1922 54.9856 83.4861 54.9856 78.5668C54.9856 73.7235 58.5446 69.9365 63.0834 69.9365C67.6458 69.9365 71.2191 73.7283 71.2191 78.5668C71.2191 83.4813 67.7168 87.1922 63.0834 87.1922Z"
        fill="black"
      />
      <path
        d="M107.823 67.7384V88.7145C107.823 90.5462 106.365 92.0305 104.571 92.0305C103.544 92.0305 102.569 91.5262 101.959 90.6841L89.6298 73.6425L89.7907 89.3425C89.8002 90.0609 89.5304 90.7317 89.0382 91.2408C88.546 91.7498 87.8881 92.0305 87.1877 92.0305C85.7536 92.0305 84.5894 90.8411 84.5894 89.371V68.4425C84.5894 66.5918 86.0565 65.0884 87.8692 65.0884C88.8962 65.0884 89.8806 65.5927 90.4959 66.4348L102.778 83.2338L102.65 67.7574C102.645 67.0437 102.91 66.3777 103.402 65.8734C103.89 65.3691 104.543 65.0884 105.239 65.0884C106.663 65.0884 107.823 66.2778 107.823 67.7384Z"
        fill="black"
      />
      <path
        d="M136.215 89.5565C136.215 90.9409 135.145 92.0352 133.83 92.0352H118.727C117.553 92.0352 116.597 91.0599 116.597 89.8562V67.272C116.597 66.0731 117.553 65.093 118.727 65.093H133.83C135.145 65.093 136.215 66.1873 136.215 67.5336C136.215 68.9181 135.145 70.0171 133.83 70.0171H122.097V75.9926H129.944C131.26 75.9926 132.334 77.0869 132.334 78.4333C132.334 79.7796 131.26 80.8739 129.944 80.8739H122.097V87.1206H133.83C135.145 87.1158 136.215 88.2101 136.215 89.5565Z"
        fill="black"
      />
      <path
        d="M162.808 67.5671C162.808 67.9905 162.7 68.471 162.491 68.8421L155.302 81.0501V81.0548C155.302 81.15 155.297 81.1785 155.297 81.2166C154.606 101.598 146.248 120.633 131.752 134.853C117.184 149.136 97.9737 157 77.67 157C34.8429 157 0 121.789 0 78.5048C0 35.2156 34.8429 0 77.67 0C103.644 0 127.786 13.0405 142.259 34.8873C142.68 35.5248 142.831 36.2908 142.68 37.0425C142.533 37.7989 142.107 38.446 141.478 38.8694C140.848 39.3023 140.091 39.4546 139.348 39.3023C138.6 39.1549 137.961 38.7219 137.535 38.0844C124.132 17.8314 101.751 5.74239 77.6748 5.74239C37.9807 5.74239 5.72196 38.3794 5.72196 78.5C5.72196 118.616 37.9807 151.253 77.6748 151.253C96.4876 151.253 114.288 143.959 127.79 130.724C141.241 117.536 148.989 99.8092 149.613 80.9121L142.509 68.7755C142.074 68 142.083 67.0818 142.524 66.3111C142.969 65.5499 143.759 65.0884 144.634 65.0884H144.862C145.737 65.0884 146.551 65.5737 146.987 66.3444L152.548 76.2735L158.251 66.3254C158.681 65.5642 159.491 65.0884 160.362 65.0884C161.715 65.0884 162.808 66.2017 162.808 67.5671Z"
        fill="black"
      />
      <path
        d="M331.031 71.0448L327.141 68.804L323.199 66.5299C320.586 65.0217 317.325 66.92 317.325 69.9506V70.4597C317.325 70.7404 317.472 70.9973 317.713 71.1352L319.545 72.1914C320.051 72.4864 320.686 72.1153 320.686 71.5254V70.3169C320.686 69.7175 321.329 69.3416 321.845 69.6414L325.451 71.7204L329.044 73.7947C329.564 74.0944 329.564 74.8461 329.044 75.1459L325.451 77.2202L321.845 79.2992C321.329 79.599 320.686 79.2231 320.686 78.6237V78.0242C320.686 74.9936 317.429 73.1001 314.812 74.6035L310.87 76.8776L306.979 79.1184C304.353 80.6314 304.353 84.4422 306.979 85.9551L310.87 88.1959L314.812 90.47C317.425 91.9782 320.686 90.0799 320.686 87.0493V86.5355C320.686 86.2548 320.539 85.9979 320.297 85.8599L318.466 84.8037C317.959 84.5088 317.325 84.8799 317.325 85.4698V86.6782C317.325 87.2777 316.682 87.6535 316.166 87.3538L312.559 85.2747L308.967 83.2004C308.446 82.9007 308.446 82.149 308.967 81.8493L312.559 79.775L316.166 77.6959C316.682 77.3962 317.325 77.7721 317.325 78.3715V78.971C317.325 82.0015 320.581 83.895 323.199 82.3917L327.141 80.1175L331.031 77.8767C333.653 76.3686 333.653 72.5578 331.031 71.0448Z"
        fill="black"
      />
      <path
        d="M305.493 71.7917L296.373 90.7126H292.587L295.994 83.6429L290.642 71.7917H294.523L297.963 79.7274L301.726 71.7917H305.493Z"
        fill="black"
      />
      <path
        d="M214.382 78.514C214.382 76.259 212.749 74.6699 210.747 74.6699C208.74 74.6699 207.112 76.2827 207.112 78.514C207.112 80.7168 208.745 82.3582 210.747 82.3582C212.754 82.3534 214.382 80.7691 214.382 78.514ZM214.41 85.2317V83.4857C213.421 84.6941 211.949 85.4458 210.189 85.4458C206.525 85.4458 203.747 82.4914 203.747 78.514C203.747 74.5653 206.634 71.5537 210.269 71.5537C211.978 71.5537 213.421 72.3054 214.415 73.49V71.7964H217.704V85.2317H214.41Z"
        fill="black"
      />
      <path
        d="M233.668 82.2488C232.518 82.2488 232.196 81.8206 232.196 80.6883V74.7223H234.307V71.7916H232.196V68.5137H228.826V71.7916H224.487V70.4547C224.487 69.3272 224.912 68.8943 226.091 68.8943H226.829V66.2871H225.206C222.428 66.2871 221.117 67.2006 221.117 69.9933V71.7869H219.247V74.7175H221.117V85.227H224.487V74.7175H228.826V81.302C228.826 84.0424 229.868 85.227 232.594 85.227H234.331V82.244H233.668V82.2488Z"
        fill="black"
      />
      <path
        d="M245.751 77.301C245.51 75.5549 244.094 74.5083 242.438 74.5083C240.781 74.5083 239.418 75.5312 239.068 77.301H245.751ZM239.045 79.3991C239.286 81.3877 240.701 82.5153 242.495 82.5153C243.91 82.5153 245.008 81.8445 245.647 80.7693H249.097C248.297 83.619 245.756 85.446 242.414 85.446C238.377 85.446 235.542 82.5962 235.542 78.538C235.542 74.4797 238.538 71.5491 242.495 71.5491C246.48 71.5491 249.367 74.5035 249.367 78.538C249.367 78.8329 249.338 79.1279 249.286 79.3991H239.045Z"
        fill="black"
      />
      <path
        d="M270.783 78.5142C270.783 76.34 269.15 74.67 267.148 74.67C265.141 74.67 263.513 76.2829 263.513 78.5142C263.513 80.7169 265.146 82.3583 267.148 82.3583C269.15 82.3535 270.783 80.6884 270.783 78.5142ZM260.196 90.7126V71.7917H263.485V73.5377C264.474 72.3008 265.946 71.5491 267.707 71.5491C271.318 71.5491 274.148 74.5321 274.148 78.4809C274.148 82.4297 271.261 85.4412 267.626 85.4412C265.941 85.4412 264.55 84.7704 263.589 83.6428V90.7126H260.196Z"
        fill="black"
      />
      <path
        d="M285.999 78.514C285.999 76.259 284.371 74.6699 282.364 74.6699C280.357 74.6699 278.729 76.2827 278.729 78.514C278.729 80.7168 280.362 82.3582 282.364 82.3582C284.371 82.3534 285.999 80.7691 285.999 78.514ZM286.027 85.2317V83.4857C285.038 84.6941 283.566 85.4458 281.806 85.4458C278.142 85.4458 275.364 82.4914 275.364 78.514C275.364 74.5653 278.251 71.5537 281.886 71.5537C283.595 71.5537 285.043 72.3054 286.032 73.49V71.7964H289.321V85.2317H286.027Z"
        fill="black"
      />
      <path
        d="M254.27 73.1096C254.27 73.1096 255.108 71.5491 257.157 71.5491C258.032 71.5491 258.6 71.8536 258.6 71.8536V75.279C258.6 75.279 257.365 74.513 256.229 74.6653C255.093 74.8223 254.374 75.8689 254.379 77.2724V85.2271H250.985V71.7917H254.275V73.1096H254.27Z"
        fill="black"
      />
      <path
        d="M172.085 85.1558V67.0437H175.45V73.4617C176.429 72.3436 177.835 71.5967 179.529 71.5967C183.131 71.5967 185.914 74.5512 185.914 78.4666C185.914 82.3821 183.051 85.3651 179.449 85.3651C177.698 85.3651 176.297 84.6182 175.341 83.4193V85.151H172.085V85.1558ZM182.577 78.4999C182.577 76.34 180.963 74.6891 178.976 74.6891C176.988 74.6891 175.374 76.2877 175.374 78.4999C175.374 80.6837 176.988 82.306 178.976 82.306C180.959 82.306 182.577 80.6551 182.577 78.4999Z"
        fill="#636A70"
      />
      <path
        d="M186.941 90.0609V87.1302H188.03C188.929 87.1302 189.804 86.6782 190.069 85.2938C190.15 84.8941 190.121 84.6801 189.965 84.2566L185.034 71.8394H188.479L192.005 81.0548L195.422 71.8394H198.759L193.832 84.3327C192.218 88.4623 191.764 90.0609 187.925 90.0609H186.941Z"
        fill="#636A70"
      />
    </svg>
  )
}

export default MoneyByAfterpay
